

.wrapper {
    display: grid;
    grid-template-columns: repeat(24, 24px);
    grid-template-rows: repeat(7,24px);
    grid-gap: 1.25rem;
    /*max-height: 12vh;*/
}

.item {
    background-color: #00000010;
}