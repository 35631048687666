.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.bg-img {
    background-image: url('../src/img/cases/epic/bg.png');
    background-position: center;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.active-route {
    opacity: 1;
}

.inactive-route {
    opacity: 0.4;
    transition: opacity 300ms ease-in-out;
}

.inactive-route:hover {
    opacity: 1;
}

.case-footer-card {
    transition: all 300ms ease;
}

.team-pic {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%) saturate(120%) brightness(120%);
}

.case-footer-card img {
    transition: all 300ms ease;
}

.case-footer-card p {
    transition: all 300ms ease;
    opacity: 0;
}

.case-footer-card svg {
    transition: all 300ms ease;
    opacity: 0;
}

.case-footer-card:hover img {
    opacity: .1;
}

.case-footer-card:hover p {
    opacity: 1;
}

.case-footer-card:hover svg {
    opacity: 1;
}

.nav-dropdown-wrapper {
    position: relative;
}

.nav-dropdown {
    position: absolute;
    margin-top: -4px;
    border-width: 0.5px;
    border: solid;
    width: 300px;
    padding: 4px;
    z-index: 9999;
}

.nav-dropdown li {
    padding: 12px 12px;
    backdrop-filter: blur(4px);
    cursor: pointer;
}

.nav-dd-item-dark:hover, .nav-dd-item:hover {
    background-color: #00000010;
}

.nav-dd-item-dark {
    color: white;
}

.nav-dd-item {
    color: black;
}

.hover-underline:hover {
    text-decoration: underline;
}
