/*CAPABILITIES SECTION*/

.services-section {
    /*min-height: 55vh;*/
    /*border-top: 1px solid var(--base-black-20);*/
    border-bottom: 1px solid var(--base-black-20);
}

.services-section-left {
    border-right: 1px solid var(--base-black-20);
}

.services-section-right {
    border-top: 1px solid var(--base-black-20);
    padding-right: 15vw !important;
}

.capability-list-item {
    border-top: 1px solid var(--base-black-20);
    display: flex;
    flex-direction: column;
    transition: all 150ms ease-in-out;
    padding-left: var(--page-h-padding);
}

.capability-list-item:hover {
    background-color: var(--base-black-4);
}

.capability-list-item p {
    transition: 150ms ease-in-out;
}

/*.capability-list-item:hover p {*/
/*    transform: scale(1.1);*/
/*}*/

/*FEATURE SECTION*/

.feature-tile {
    border: 1.5px solid transparent;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.feature-tile-dark {
    border: 1.5px solid black;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/*HERO*/

.hero-badge {
    bottom: -42px;
    left: var(--page-h-padding);
    /*background-color: var(--base-white-30);*/
    width: 8vw;
    height: 8vw;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    shape-outside: circle();
    float: left;
    /*animation: rotate 26s linear infinite;*/
    /*margin-right: 20px;*/
}

.badge-outer {
    width: 18vw;
    animation: rotate 60s linear infinite;
}

.badge-outer-mobile {
    width: 40vw;
    animation: rotate 60s linear infinite;
}

.rotate {
    animation: rotate 60s linear infinite;
}

.rotate-fast {
    animation: rotate 12s linear infinite;
}

.rotate-rev-slow {
    animation: rotate-rev 260s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes rotate-rev {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

.float {
    animation: float 80s ease-in-out infinite;
}


@keyframes float {
    0% {
        transform: translate(0, 0) rotate(0);
    }
    5% {
        transform: translate(-3%, -5%) rotate(-3deg);
    }
    10% {
        transform: translate(-2%, -2%) rotate(-2deg);
    }
    15% {
        transform: translate(-1%, -5%) rotate(-1deg);
    }
    20% {
        transform: translate(0, 0) rotate(0);
    }
    25% {
        transform: translate(1%, -5%) rotate(1deg);
    }
    30% {
        transform: translate(2%, -2%) rotate(2deg);
    }
    35% {
        transform: translate(3%, -5%) rotate(3deg);
    }
    40% {
        transform: translate(0, 0) rotate(0);
    }
    45% {
        transform: translate(-2%, 5%) rotate(-2deg);
    }
    50% {
        transform: translate(-1%, 2%) rotate(-1deg);
    }
    55% {
        transform: translate(-3%, 5%) rotate(-3deg);
    }
    60% {
        transform: translate(0, 0) rotate(0);
    }
    65% {
        transform: translate(3%, 5%) rotate(3deg);
    }
    70% {
        transform: translate(1%, 2%) rotate(1deg);
    }
    75% {
        transform: translate(2%, 5%) rotate(2deg);
    }
    80% {
        transform: translate(0, 0) rotate(0);
    }
    85% {
        transform: translate(-5%, 0) rotate(-2deg);
    }
    90% {
        transform: translate(-2%, 0) rotate(-1deg);
    }
    95% {
        transform: translate(-5%, 0) rotate(-3deg);
    }
    100% {
        transform: translate(0, 0) rotate(0);
    }
}

.fade-in-on-scroll {
    opacity: 0;
    transition: all 800ms ease-out;
    transform: translateY(40px);
}

.fade-in-on-scroll.visible {
    opacity: 1;
    transform: translateY(0px);
}

.my-super-cool-btn {
    position: relative;
    text-decoration: none;
    color: #fff;
    letter-spacing: 1px;
    font-size: 1rem;
    box-sizing: border-box;
}

.my-super-cool-btn span {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6vw;
    height: 6vw;
    margin-left: -1vw;
}

.my-super-cool-btn span:before {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    border-radius: 100%;
    border: 7px solid #000000D0;
    box-sizing: border-box;
    transition: all .85s cubic-bezier(0.25, 1, 0.33, 1);
    box-shadow: 0 30px 85px rgba(0, 0, 0, 0.14), 0 15px 35px rgba(0, 0, 0, 0.14);
}

.my-super-cool-btn:hover span:before {
    transform: scale(0.8);
    box-shadow: 0 20px 55px rgba(0, 0, 0, 0.14), 0 15px 35px rgba(0, 0, 0, 0.14);
}

.my-super-cool-btn .dots-container {
    opacity: 0;
    animation: intro 1.6s;
    animation-fill-mode: forwards;
}

.my-super-cool-btn .dot {
    width: 8px;
    height: 8px;
    display: block;
    background-color: #000000D0;
    position: absolute;
    transition: all .85s cubic-bezier(0.25, 1, 0.33, 1);
}

.my-super-cool-btn .dot:nth-child(1) {
    top: 50px;
    left: 50px;
    transform: rotate(-140deg);
    animation: swag1-out 0.3s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.my-super-cool-btn .dot:nth-child(2) {
    top: 50px;
    right: 50px;
    transform: rotate(140deg);
    animation: swag2-out 0.3s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.my-super-cool-btn .dot:nth-child(3) {
    bottom: 50px;
    left: 50px;
    transform: rotate(140deg);
    animation: swag3-out 0.3s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.my-super-cool-btn .dot:nth-child(4) {
    bottom: 50px;
    right: 50px;
    transform: rotate(-140deg);
    animation: swag4-out 0.3s;
    animation-fill-mode: forwards;
    opacity: 0;
}

.my-super-cool-btn:hover .dot:nth-child(1) {
    animation: swag1 0.3s;
    animation-fill-mode: forwards;
}

.my-super-cool-btn:hover .dot:nth-child(2) {
    animation: swag2 0.3s;
    animation-fill-mode: forwards;
}

.my-super-cool-btn:hover .dot:nth-child(3) {
    animation: swag3 0.3s;
    animation-fill-mode: forwards;
}

.my-super-cool-btn:hover .dot:nth-child(4) {
    animation: swag4 0.3s;
    animation-fill-mode: forwards;
}

@keyframes intro {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes swag1 {
    0% {
        top: 50px;
        left: 50px;
        width: 8px;
    }
    50% {
        width: 30px;
        opacity: 1;
    }
    100% {
        top: 20px;
        left: 20px;
        width: 8px;
        opacity: 1;
    }
}

@keyframes swag1-out {
    0% {
        top: 20px;
        left: 20px;
        width: 8px;
    }
    50% {
        width: 30px;
        opacity: 1;
    }
    100% {
        top: 50px;
        left: 50px;
        width: 8px;
        opacity: 0;
    }
}

@keyframes swag2 {
    0% {
        top: 50px;
        right: 50px;
        width: 8px;
    }
    50% {
        width: 30px;
        opacity: 1;
    }
    100% {
        top: 20px;
        right: 20px;
        width: 8px;
        opacity: 1;
    }
}

@keyframes swag2-out {
    0% {
        top: 20px;
        right: 20px;
        width: 8px;
    }
    50% {
        width: 30px;
        opacity: 1;
    }
    100% {
        top: 50px;
        right: 50px;
        width: 8px;
        opacity: 0;
    }
}

@keyframes swag3 {
    0% {
        bottom: 50px;
        left: 50px;
        width: 8px;
    }
    50% {
        width: 30px;
        opacity: 1;
    }
    100% {
        bottom: 20px;
        left: 20px;
        width: 8px;
        opacity: 1;
    }
}

@keyframes swag3-out {
    0% {
        bottom: 20px;
        left: 20px;
        width: 8px;
    }
    50% {
        width: 30px;
        opacity: 1;
    }
    100% {
        bottom: 50px;
        left: 50px;
        width: 8px;
        opacity: 0;
    }
}

@keyframes swag4 {
    0% {
        bottom: 50px;
        right: 50px;
        width: 8px;
    }
    50% {
        width: 30px;
        opacity: 1;
    }
    100% {
        bottom: 20px;
        right: 20px;
        width: 8px;
        opacity: 1;
    }
}

@keyframes swag4-out {
    0% {
        bottom: 20px;
        right: 20px;
        width: 8px;
    }
    50% {
        width: 30px;
        opacity: 1;
    }
    100% {
        bottom: 50px;
        right: 50px;
        width: 8px;
        opacity: 0;
    }
}


.about-highlight-title {
    position: relative;
    /*padding: 8px 24px;*/
    /*margin-left: -24px;*/

}

.about-highlight-title:hover {
    /*background-color: var(--base-white-8);*/
    backdrop-filter: blur(6px);
    /*box-shadow: 0 8px 20px #00000008;*/
}

.about-block__content {
    position: absolute;
    /*opacity: 0;*/
    backdrop-filter: blur(6px);
    /*box-shadow: 0 8px 20px #00000008;*/
    /*background-color: #ffffff50;*/
    padding: 40px;
    overflow: hidden;
}

.highlight-progress {
    /*width: 100%;*/
    display: flex;
    flex-direction: row;
    /*margin: 0 8px 8px 8px*/
}

.highlight-progress div {
    height: 4px;
    display: flex;
    background-color: var(--base-black-80);
    flex: 1;
    /*margin: 8px;*/
}

.highlight-progress-dark {
    /*width: 100%;*/
    display: flex;
    flex-direction: row;
    /*margin: 0 8px 8px 8px*/
}

.highlight-progress-dark div {
    height: 4px;
    display: flex;
    background-color: var(--base-white-80);
    flex: 1;
    /*margin: 8px;*/
}

.highlight {
    background: linear-gradient(104deg, hsla(280, 65%, 85%, 0) 0.9%, #ffb6ff 2.4%, hsla(280, 65%, 85%, 0.5) 5.8%, hsla(280, 65%, 85%, 0.1) 93%, hsla(280, 65%, 85%, 0.7) 96%), linear-gradient(183deg, rgba(188, 130, 255, 0), rgba(255, 152, 196, 0.3) 7.9%, hsla(280, 65%, 85%, 0) 15%);
    border-radius: 7.5px;
    -webkit-box-decoration-break: clone;
    font-weight: bolder;
    margin: 0;
    padding: 0.1em 6px;
    text-shadow: 12px 12px 9.8px #ffb6ff, 21px 18.1px 7.3px #fff, -18.1px -27.3px 30px #fff;
}

.highlight-swipe {
    background: linear-gradient(104deg, hsla(280, 65%, 85%, 0) 0.9%, #ffb6ff 2.4%, hsla(280, 65%, 85%, 0.5) 5.8%, hsla(280, 65%, 85%, 0.1) 3%, hsla(280, 65%, 85%, 0.7) 6%), linear-gradient(183deg, rgba(188, 130, 255, 0), rgba(255, 152, 196, 0.3) 4%, hsla(280, 65%, 85%, 0) 8%);
    border-radius: 7.5px;
    -webkit-box-decoration-break: clone;
    font-weight: bolder;
    margin: 0;
    padding: 0.1em 6px;
    text-shadow: 12px 12px 9.8px #ffb6ff, 21px 18.1px 7.3px #fff, -18.1px -27.3px 30px #fff;
}

.highlight-2 {
    background: linear-gradient(104deg, hsla(280, 65%, 85%, 1) 20%, #ffb6ff 2.4%, hsla(280, 65%, 85%, 1) 5.8%, hsla(280, 65%, 85%, 1) 93%, hsla(280, 65%, 85%, 1) 96%), linear-gradient(183deg, rgba(188, 130, 255, 1), rgba(255, 152, 196, 1) 7.9%, hsla(280, 65%, 85%, 1) 15%);
    border-radius: 7.5px;
    -webkit-box-decoration-break: clone;
    font-weight: bolder;
    margin: 0;
    padding: 0.1em 6px;
    text-shadow: 12px 12px 9.8px #ffb6ff, 21px 18.1px 7.3px #fff, -18.1px -27.3px 30px #fff;
}


.process-item {
    /*background-color: red;*/
    padding: 20px 24px;
    border: 1px solid transparent;
    transition: 300ms ease-in-out;
    color: var(--base-black-70);
}

.process-item:hover {
    /*background-color: var(--base-black-4);*/
    border: 1px solid var(--base-black-20);
    backdrop-filter: blur(12px);
    color: var(--base-black-100);
}

.team-image {
    overflow: hidden;
    box-shadow: 0 8px 20px #00000016;
}

.team-image img {
    transform: scale(1.05);
    transition: 300ms ease-in-out;
}

.team-image:hover img {
    transform: scale(1.1);
}

a {
    text-decoration: none;
}


.case-hero-section {
    height: 75vh;
    /*background: linear-gradient(83.9deg, #032549 8.2%, #166DB9 90.7%);*/
    padding: 28vh 0 0 0;
}

.blue-gradient-bg {
    background: radial-gradient(73.16% 78.62% at 50% 50%, #d5e7ff 0%, #f8fcff 100%)
}

.image-slider {
    /*border: 2px solid #00000020;*/
    /*padding:4px 4px 0px 4px;*/
    /*background-color: #00000020;*/
}

.marquee {
    margin-bottom: -1.9vw;
}

.spinner {
    height: 1em;
    width: 1em;
    border: 1px solid var(--base-white-20);
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    border-left-color: var(--base-white-50);
    animation: spin 1.2s linear infinite;
    box-sizing: border-box;
}


.hero-box {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 40px;
    background-color: var(--base-white-20);
    text-align: left;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    box-shadow: 0 8px 20px #00000008;
    padding: 20px 40px
}

.hero-box-tablet {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 40px;
    background-color: var(--base-white-20);
    text-align: left;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    box-shadow: 0 8px 20px #00000008;
    padding: 12px 20px;
}

.hero-box-mobile {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--base-white-20);
    text-align: left;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    box-shadow: 0 8px 20px #00000008;
    padding: 12px 16px
}


@keyframes spin {
    100% {
        transform: rotate(-360deg);
    }
}

.text-with-stroke {
    color: #ffffff;
    text-shadow: -1px -1px 0 black,
    1px -1px 0 black,
    -1px 1px 0 black,
    1px 1px 0 black;
}

.text-with-stroke-50 {
    color: #ffffff50;
    text-shadow: -1px -1px 0 black,
    1px -1px 0 black,
    -1px 1px 0 black,
    1px 1px 0 black;
}

.sticky-image-container {
    position: -webkit-sticky;
    position: sticky;
    top: 35%;
    /*transform: translateY(-50%);*/
}


.sticky-hero-container {
    position: -webkit-sticky;
    position: sticky;
    top: 18%;
    /*transform: translateY(-50%);*/
}

.sticky-text-container-1 {
    position: -webkit-sticky;
    position: sticky;
    top: 35%;
    /*transform: translateY(-50%);*/
}

.sticky-text-container-1-mobile {
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    /*transform: translateY(-50%);*/
}


.sticky-text-container-2 {
    position: -webkit-sticky;
    position: sticky;
    top: 42%;
    /*transform: translateY(-50%);*/
}

.sticky-section-container {
    /*position: sticky;*/
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
}

.lab-widget {
    transition: 150ms ease-in;
    outline-offset: 0px;
    outline: 3px solid transparent;
    /*cursor: pointer;*/
}

.lab-widget:hover {
    outline-offset: 4px;
    outline: 3px solid black;
}

.tags-row {
    opacity: 0;
    transform: translateY(8px);
    transition: all 150ms ease-in-out;
}

.work-item:hover .tags-row {
    opacity: 1;
    transform: translateY(0px);
}

.work-item {
    width: min-content;
    /*overflow: hidden; !* Hide overflow to crop excess content *!*/
    position: relative;
}

.work-item img {
    transition: 150ms ease-in-out;
    /*outline: 2px solid transparent;*/
    /*outline-offset: 0px;*/
}

.work-item:hover img {
    transform: scale(1.05);
    /*outline: 2px solid #00000020;*/
    /*outline-offset: 3px;*/
}


.capabilities-card {
    border-radius: 0px;
    /*border:1px solid #00000020;*/
    padding: 32px;
    box-shadow: 0px 4px 12px #00000010;
    border: 1px solid transparent;
    transition: 150ms ease-in-out;
}

.capabilities-card:hover {
    border-radius: 0px;
    /*border:1px solid #00000020;*/
    padding: 32px;
    box-shadow: 0px 4px 12px #00000010;
    border: 1px solid #00000030;
    background-image: linear-gradient(to right, #3b82f610, #9333ea10, #9333ea10, #9333ea10);
}

.vertical-card {
    border-radius: 0px;
    /*border:1px solid #00000020;*/
    box-shadow: 0px 4px 12px #00000010;
    border: 1px solid transparent;
    transition: 150ms ease-in-out;
    /*cursor: pointer;*/
}

.vertical-card:hover {
    border-radius: 0px;
    /*border:1px solid #00000020;*/
    padding: 32px;
    box-shadow: 0px 4px 12px #00000010;
    border: 1px solid #00000030;
    background-image: linear-gradient(to right, #3b82f610, #9333ea10, #9333ea10, #9333ea10);
}

.vertical-card .cta {
    /*opacity: .2;*/
    transition: 150ms ease-in-out;
    transform: translateY(0px);
}

.vertical-card:hover .cta {
    opacity: 1;
    transform: translateY(-4px);
}

.dots-section {
    position: relative;
    z-index: 1; /* Ensure that the dots section is in front of the dots element */
}

.dots {
    position: fixed;
    top: 50%; /* Adjust this value to position the dots vertically */
    left: 50%; /* Adjust this value to position the dots horizontally */
    transform: translate(-50%, -50%);
    z-index: 2; /* Place the dots element above the content */
    display: none; /* Initially hide the dots */
}

.boost-saturation {
    filter: saturate(125%); /* Adjust the percentage as needed */
}

.boost-saturation-2 {
    filter: saturate(145%); /* Adjust the percentage as needed */
}

.custom-cursor {
    width: 20px;
    height: 20px;
    background-color: #ff0000; /* Red color */
    border-radius: 50%; /* Make it a circle */
    pointer-events: none; /* Ensure it doesn't interfere with other elements */
    mix-blend-mode: difference; /* For better visibility */
    transition: transform 0.1s; /* Add smooth transitions */
}

.inverseFilter {
    -webkit-filter: invert(100%); /* Safari/Chrome */
    filter: invert(100%);
}

@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}

.animate-path {
    animation: draw 4s linear forwards; /* Adjust the duration as needed */
}
