
button {
    outline: none !important;
    stroke: none !important;
    border-width: 0 !important;
}

.primary-button {
    background: #000000DD;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    padding: 0px 32px;
    width: min-content;
    white-space: nowrap;
    cursor: pointer;
    transition: 300ms ease-in-out;
    box-shadow: 0 8px 20px #00000010;
    border: 2px solid transparent !important;
}

.primary-button:hover {
    box-shadow: 0 2px 12px #00000030;
    /*background-color: var(--dull-red);*/
    background-color: var(--base-black-80);
    border: 2px solid black !important;
    background-image: radial-gradient(at 11% 93%, hsla(31, 100%, 98%, 1) 0px, transparent 50%),
    radial-gradient(at 95% 4%, hsla(31, 100%, 98%, 1) 0px, transparent 50%),
    radial-gradient(at 45% 81%, hsla(62, 100%, 63%, 0.2) 0px, transparent 50%),
    radial-gradient(at 13% 99%, hsla(174, 100%, 63%, 0.65) 0px, transparent 50%),
    radial-gradient(at 63% 53%, hsla(264, 100%, 70%, 0.8) 0px, transparent 50%),
    radial-gradient(at 96% 29%, hsla(244, 100%, 53%, 0.55) 0px, transparent 50%),
    radial-gradient(at 30% 54%, hsla(310, 100%, 74%, 1) 0px, transparent 50%),
    radial-gradient(at 89% 91%, hsl(30, 3%, 15%) 0px, transparent 50%),
    radial-gradient(at 68% 40%, hsl(0, 0%, 2%) 0px, transparent 50%),
    radial-gradient(at 98% 50%, hsl(0, 0%, 0%) 0px, transparent 50%),
    radial-gradient(at 73% 18%, hsla(163, 100%, 77%, 1) 0px, transparent 50%),
    radial-gradient(at 49% 22%, hsla(23, 100%, 70%, 1) 0px, transparent 50%),
    radial-gradient(at 26% 24%, hsl(0, 0%, 5%) 0px, transparent 50%),
    radial-gradient(at 99% 99%, hsla(250, 75%, 98%, 1) 0px, transparent 50%),
    radial-gradient(at 99% 0%, hsl(0, 0%, 0%) 0px, transparent 50%);
}

.secondary-button {
    background: var(--base-white-100);
    color: var(--base-black-100);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    padding: 0px 32px;
    border: 2px solid transparent;
    width: min-content;
    white-space: nowrap;
    cursor: pointer;
    transition: 150ms ease;
}

.secondary-button:hover {
    transform: translateY(-1px);
    /*background-color: var(--base-white-50);*/
    border: 2px solid var(--base-black-10)
}

.secondary-button-dark:hover {
    transform: translateY(-1px);
    /*background-color: var(--base-white-50);*/
    /*border: 2px solid var(--base-black-30);*/
    outline: 2px solid var(--base-white-50);
    outline-offset: 2px;
}

@media screen and (max-width: 1000px) {
    .secondary-button, .primary-button {
        padding: 0px 18px;
        height: 36px;
    }
}

@media screen and (min-width: 1001px) and (max-width: 1199px) {
    .secondary-button, .primary-button {
        padding: 0px 24px;
        height: 48px;
    }
}

@media screen and (min-width: 1200px) {
    .secondary-button, .primary-button {
        padding: 0px 32px;
        height: 54px;
    }
}

/*button {*/
/*	 position: relative;*/
/*	 display: inline-block;*/
/*	 cursor: pointer;*/
/*	 outline: none;*/
/*	 border: 0;*/
/*	 vertical-align: middle;*/
/*	 text-decoration: none;*/
/*	 background: transparent;*/
/*	 padding: 0;*/
/*	 font-size: inherit;*/
/*	 font-family: inherit;*/
/*}*/
/* button.learn-more {*/
/*	 width: 12rem;*/
/*	 height: auto;*/
/*}*/
/* button.learn-more .circle {*/
/*	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);*/
/*	 position: relative;*/
/*	 display: block;*/
/*	 margin: 0;*/
/*	 width: 3rem;*/
/*	 height: 3rem;*/
/*	 background: var(--primary);*/
/*	 border-radius: 1.625rem;*/
/*}*/
/* button.learn-more .circle .icon {*/
/*	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);*/
/*	 position: absolute;*/
/*	 top: 0;*/
/*	 bottom: 0;*/
/*	 margin: auto;*/
/*	 background: #fff;*/
/*}*/
/* button.learn-more .circle .icon.arrow {*/
/*	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);*/
/*	 left: 0.625rem;*/
/*	 width: 1.125rem;*/
/*	 height: 0.125rem;*/
/*	 background: none;*/
/*}*/
/* button.learn-more .circle .icon.arrow::before {*/
/*	 position: absolute;*/
/*	 content: '';*/
/*	 top: -0.25rem;*/
/*	 right: 0.0625rem;*/
/*	 width: 0.625rem;*/
/*	 height: 0.625rem;*/
/*	 border-top: 0.125rem solid #fff;*/
/*	 border-right: 0.125rem solid #fff;*/
/*	 transform: rotate(45deg);*/
/*}*/
/* button.learn-more .button-text {*/
/*	 transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);*/
/*	 position: absolute;*/
/*	 top: 0;*/
/*	 left: 0;*/
/*	 right: 0;*/
/*	 bottom: 0;*/
/*	 padding: 0.75rem 0;*/
/*	 margin: 0 0 0 1.85rem;*/
/*	 color: var(--primary);*/
/*	 font-weight: 700;*/
/*	 line-height: 1.6;*/
/*	 text-align: center;*/
/*	 text-transform: uppercase;*/
/*}*/
/* button:hover .circle {*/
/*	 width: 100%;*/
/*}*/
/* button:hover .circle .icon.arrow {*/
/*	 background: #fff;*/
/*	 transform: translate(1rem, 0);*/
/*}*/
/* button:hover .button-text {*/
/*	 color: #fff;*/
/*}*/
/* @supports (display: grid) {*/
/*	 body {*/
/*		 display: grid;*/
/*		 grid-template-columns: repeat(4, 1fr);*/
/*		 grid-gap: 0.625rem;*/
/*		 grid-template-areas: ". main main ." ". main main .";*/
/*	}*/
/*	 #container {*/
/*		 grid-area: main;*/
/*		 align-self: center;*/
/*		 justify-self: center;*/
/*	}*/
/*}*/
