.animated-input {
    position: relative;

}

.animated-input__input {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    border: 1.5px solid var(--base-black-30);
    /*font-size: 18px;*/
    padding: 10px;
    outline: none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border-radius: 0px !important;
    background-color: var(--base-black-10);
    font-family: 'Manrope', sans-serif;
    transition: 300ms ease-in-out;
}

.animated-multiline-input__label {
    position: absolute;
    top: 24px;
    left: 10px;
    font-weight: 600;
    transform: translateY(-50%);
    /*font-size: 18px;*/
    color: var(--base-black-50);
    pointer-events: none;
    transition: all 0.2s ease-in-out;
}

.animated-input__label {
    position: absolute;
    top: 50%;
    left: 10px;
    font-weight: 600;
    transform: translateY(-50%);
    /*font-size: 18px;*/
    color: var(--base-black-50);
    pointer-events: none;
    transition: all 0.2s ease-in-out;
}

.animated-input__label--focused {
    top: -34%;
    left: 0px;
    /*font-size: 14px;*/
    color: var(--base-black-100);
    font-weight: 900 !important;
}

.animated-input__label--filled {
    top: -28%;
    left: 0px;
    color: var(--base-black-70);
    font-weight: 900 !important;
}

.animated-multiline-input__label--focused {
    top: -16px;
    left: 0px;
    /*font-size: 14px;*/
    color: var(--base-black-100);
    font-weight: 900 !important;
}

.animated-multiline-input__label--filled {
    top:-14px;
    left: 0px;
    color: var(--base-black-70);
    font-weight: 900 !important;
}

.animated-input__input:hover {
    border: 1.5px solid var(--base-black-100);
}

.animated-input__input:focus {
    border: 1.5px solid var(--base-black-100);
}

.file-drop-area {
    background-color: var(--base-black-10);
    border: 1.5px solid var(--base-black-30);
    transition: 300ms ease-in-out;
    padding: 12px;
    cursor: pointer;
}

.file-drop-area:hover {
    background-color: var(--base-black-30);
    border: 1.5px solid var(--base-black-100);
}

.uploaded-file {
    padding: 4px;
    position: relative;
}

.uploaded-file:hover {
    /*background-color: var(--base-white-20);*/
}

.uploaded-file div {
    opacity: 0;
    cursor: pointer;
    color:var(--base-white-100);
    transition: 300ms ease-in-out;
    transform: translateY(20px);
}

.uploaded-file:hover div {
    opacity: 1;
    transform: translateY(0px);
}


