:root {
    --base-black-100: #000000;
    --base-black-80: #000000DD;
    --base-black-70: #000000B2;
    --base-black-50: #00000080;
    --base-black-30: #0000004d;
    --base-black-20: #00000033;
    --base-black-10: #0000001a;
    --base-black-8: #00000014;
    --base-black-4: #00000007;
    --base-white-100: #FFFFFF;
    --base-white-80: #FFFFFFCD;
    --base-white-70: #FFFFFFB2;
    --base-white-50: #FFFFFF80;
    --base-white-30: #FFFFFF4d;
    --base-white-20: #FFFFFF33;
    --base-white-10: #FFFFFF1a;
    --base-white-8: #FFFFFF14;
    --base-white-4: #FFFFFF0a;


    --off-white: #F9F8FF;

    --blacks-1: #1A1C1E;
    --blacks-2: #030410;
    --blacks-3: #111315;
    --blacks-nav-bar-squares: #272B30;
    --blacks-dropdown: #23262F;
    --server-channel-grey: #5A5A5C;
    --server-channel-title: #868B90;
    --widget-hover: #363845;
    --scrubber: #949FA5;
    --card-blue: #242731;
    --blacks-search-bar: #19191C;

    --neon-red: #E93F8E;
    --bright-red: #E53635;
    --dull-red: #F04748;
    --dark-red: #600d08;

    --neon-yellow: #FAF828;
    --bright-yellow: #FDCB80;
    --dull-yellow: #F5B544;
    --dark-yellow: #785A00;

    --neon-green: #5BE374;
    --bright-green: #3AD170;
    --dull-green: #70C27E;
    --dark-green: #0D523B;

    --neon-blue: #35B9EA;
    --bright-blue: #3F8CFF;
    --dull-blue: #3C69E6;
    --dark-blue: #1C3DBE;

    --gradient-red-start: #F9A557;
    --gradient-red-end: #EA323D;
    --gradient-red: linear-gradient(to right, var(--gradient-red-start), var(--gradient-red-end));
    --gradient-orange-start: #FEB000;
    --gradient-orange-end: #F25E22;
    --gradient-orange: linear-gradient(to right, var(--gradient-orange-start), var(--gradient-orange-end));
    --gradient-yellow-start: #FFEF54;
    --gradient-yellow-start-mid: #D8F542;
    --gradient-yellow-end-mid: #E6FFA4;
    --gradient-yellow-end: #9AFF82;
    --gradient-yellow: linear-gradient(to right, var(--gradient-yellow-start), var(--gradient-yellow-start-mid), var(--gradient-yellow-end-mid), var(--gradient-yellow-end));
    --gradient-green-start: #2FEA9B;
    --gradient-green-end: #7FDD53;
    --gradient-green: linear-gradient(to right, var(--gradient-green-start), var(--gradient-green-end));
    --gradient-blue-start: #38A6EC;
    --gradient-blue-end: #2BF3E3;
    --gradient-blue: linear-gradient(to right, var(--gradient-blue-start), var(--gradient-blue-end));
    --gradient-purple-start: #967CFD;
    --gradient-purple-end: #4735E7;
    --gradient-purple: linear-gradient(to right, var(--gradient-purple-start), var(--gradient-purple-end));
    --gradient-pink-start: #FFCBED;
    --gradient-pink-mid: #FD8AFF;
    --gradient-pink-end: #FF5A63;
    --gradient-pink: linear-gradient(to right, var(--gradient-pink-start), var(--gradient-pink-mid), var(--gradient-pink-end));
    --sunset-gradient-start: #FF9898;
    --sunset-gradient-mid: #F0936B;
    --sunset-gradient-end: #FFD584;
    --sunset-gradient: linear-gradient(to right, var(--sunset-gradient-start), var(--sunset-gradient-mid), var(--sunset-gradient-end));
    --brand-gradient: linear-gradient(to right, var(--bright-yellow), var(--dull-yellow));
}

/* ---------- BASES ----------*/

.base-black-100, .h-base-black-100:hover {
    color: var(--base-black-100);
}

.bg-base-black-100, .h-bg-base-black-100:hover {
    background-color: var(--base-black-100);
}

.base-black-80, .h-base-black-80:hover {
    color: var(--base-black-80);
}

.bg-base-black-80, .h-bg-base-black-80:hover {
    background-color: var(--base-black-80);
}

.base-black-70, .h-base-black-70:hover {
    color: var(--base-black-70);
}

.bg-base-black-70, .h-bg-base-black-70:hover {
    background-color: var(--base-black-70);
}

.base-black-50, .h-base-black-50:hover {
    color: var(--base-black-50);
}

.bg-base-black-50, .h-bg-base-black-50:hover {
    background-color: var(--base-black-50);
}

.base-black-30, .h-base-black-30:hover {
    color: var(--base-black-30);
}

.bg-base-black-30, .h-bg-base-black-30:hover {
    background-color: var(--base-black-30);
}

.base-black-20, .h-base-black-20:hover {
    color: var(--base-black-20);
}

.bg-base-black-20, .h-bg-base-black-20:hover {
    background-color: var(--base-black-20);
}

.base-black-10, .h-base-black-10:hover {
    color: var(--base-black-10);
}

.bg-base-black-10, .h-bg-base-black-10:hover {
    background-color: var(--base-black-10);
}

.base-black-8, .h-base-black-8:hover {
    color: var(--base-black-8);
}

.bg-base-black-8, .h-bg-base-black-8:hover {
    background-color: var(--base-black-8);
}

.base-black-4, .h-base-black-4:hover {
    color: var(--base-black-4);
}

.bg-base-black-4, .h-bg-base-black-4:hover {
    background-color: var(--base-black-4);
}

.base-white-100, .h-base-white-100:hover {
    color: var(--base-white-100);
}


.bg-base-white-100, .h-bg-base-white-100:hover {
    background-color: var(--base-white-100);
}

.base-white-80, .h-base-white-80:hover {
    color: var(--base-white-80);
}

.bg-base-white-80, .h-bg-base-white-80:hover {
    background-color: var(--base-white-80);
}

.base-white-70, .h-base-white-70:hover {
    color: var(--base-white-70);
}

.bg-base-white-70, .h-bg-base-white-70:hover {
    background-color: var(--base-white-70);
}

.base-white-50, .h-base-white-50:hover {
    color: var(--base-white-50);
}

.bg-base-white-50, .h-bg-base-white-50:hover {
    background-color: var(--base-white-50);
}

.base-white-30, .h-base-white-30:hover {
    color: var(--base-white-30);
}

.bg-base-white-30, .h-bg-base-white-30:hover {
    background-color: var(--base-white-30);
}

.base-white-20, .h-base-white-20:hover {
    color: var(--base-white-20);
}

.bg-base-white-20, .h-bg-base-white-20:hover {
    background-color: var(--base-white-20);
}

.base-white-10, .h-base-white-10:hover {
    color: var(--base-white-10);
}

.bg-base-white-10, .h-bg-base-white-10:hover {
    background-color: var(--base-white-10);
}

.base-white-8, .h-base-white-8:hover {
    color: var(--base-white-8);
}

.bg-base-white-8, .h-bg-base-white-8:hover {
    background-color: var(--base-white-8);
}

.base-white-4, .h-base-white-4:hover {
    color: var(--base-white-4);
}

.bg-base-white-4, .h-bg-base-white-4:hover {
    background-color: var(--base-white-4);
}

/* ---------- GRAYS ----------*/

.blacks-1, .h-blacks-1:hover {
    color: var(--blacks-1);
}

.bg-blacks-1, .h-bg-blacks-1:hover {
    background-color: var(--blacks-1);
}

.blacks-2, .h-blacks-2:hover {
    color: var(--blacks-2);
}

.bg-blacks-2, .h-bg-blacks-2:hover {
    background-color: var(--blacks-2);
}

.blacks-3, .h-blacks-3:hover {
    color: var(--blacks-3);
}

.bg-blacks-3, .h-bg-blacks-3:hover {
    background-color: var(--blacks-3);
}

.blacks-nav-bar-squares, .h-blacks-nav-bar-squares:hover {
    color: var(--blacks-nav-bar-squares);
}

.bg-blacks-nav-bar-squares, .h-bg-blacks-nav-bar-squares:hover {
    background-color: var(--blacks-nav-bar-squares);
}

.blacks-dropdown, .h-blacks-dropdown:hover {
    color: var(--blacks-dropdown);
}

.bg-blacks-dropdown, .h-bg-blacks-dropdown {
    background-color: var(--blacks-dropdown);
}

.server-channel-grey, .h-server-channel-grey:hover {
    color: var(--server-channel-grey);
}

.bg-server-channel-grey, .h-bg-server-channel-grey:hover {
    background-color: var(--server-channel-grey);
}

.server-channel-title, .h-server-channel-title:hover {
    color: var(--server-channel-title);
}

.bg-server-channel-title, .h-bg-server-channel-title:hover {
    background-color: var(--server-channel-title);
}

.widget-hover, .h-widget-hover:hover {
    color: var(--widget-hover);
}

.bg-widget-hover, .h-bg-widget-hover:hover {
    background-color: var(--widget-hover);
}

.scrubber, .h-scrubber:hover {
    color: var(--scrubber);
}

.bg-scrubber, .h-bg-scrubber:hover {
    background-color: var(--scrubber);
}

.card-blue, .h-card-blue:hover {
    color: var(--card-blue);
}

.bg-card-blue, .h-bg-card-blue:hover {
    background-color: var(--card-blue);
}

.neon-red, .h-neon-red:hover {
    color: var(--neon-red);
}

.bg-neon-red, .h-bg-neon-red:hover {
    background-color: var(--neon-red);
}

.bright-red, .h-bright-red:hover {
    color: var(--bright-red);
}

.bg-bright-red, .h-bg-bright-red:hover {
    background-color: var(--bright-red);
}

.dull-red, .h-dull-red:hover {
    color: var(--dull-red);
}

.bg-dull-red, .h-bg-dull-red {
    background-color: var(--dull-red);
}

.dark-red, .h-dark-red:hover {
    color: var(--dark-red);
}

.bg-dark-red, .h-bg-dark-red:hover {
    background-color: var(--dark-red);
}

.neon-yellow, .h-neon-yellow:hover {
    color: var(--neon-yellow);
}

.bg-neon-yellow, .h-bg-neon-yellow:hover {
    background-color: var(--neon-yellow);
}

.bright-yellow, .h-bright-yellow:hover {
    color: var(--bright-yellow);
}

.bg-bright-yellow, .h-bg-bright-yellow:hover {
    background-color: var(--bright-yellow);
}

.dull-yellow, .h-dull-yellow:hover {
    color: var(--dull-yellow);
}

.bg-dull-yellow, .h-bg-dull-yellow:hover {
    background-color: var(--dull-yellow);
}

.dark-yellow, .h-dark-yellow:hover {
    color: var(--dark-yellow);
}

.bg-dark-yellow, .h-bg-dark-yellow:hover {
    background-color: var(--dark-yellow);
}

.neon-green, .h-neon-green:hover {
    color: var(--neon-green);
}

.bg-neon-green, .h-bg-neon-green:hover {
    background-color: var(--neon-green);
}

.bright-green, .h-bright-green:hover {
    color: var(--bright-green);
}

.bg-bright-green, .h-bg-bright-green:hover {
    background-color: var(--bright-green);
}

.dull-green, .h-dull-green:hover {
    color: var(--dull-green);
}

.dark-green, .h-dark-green:hover {
    color: var(--dark-green);
}

.bg-dark-green, .h-bg-dark-green:hover {
    background-color: var(--dark-green);
}

.neon-blue, .h-neon-blue:hover {
    color: var(--neon-blue);
}

.bg-neon-blue, .h-bg-neon-blue:hover {
    background-color: var(--neon-blue);
}

.bright-blue, .h-bright-blue:hover {
    color: var(--bright-blue);
}

.bg-bright-blue, .h-bg-bright-blue:hover {
    background-color: var(--bright-blue);
}

.dull-blue, .h-dull-blue:hover {
    color: var(--dull-blue);
}

.bg-dull-blue, .h-bg-dull-blue:hover {
    background-color: var(--dull-blue);
}

.dark-blue, .h-dark-blue:hover {
    color: var(--dark-blue);
}

.bg-dark-blue, .h-bg-dark-blue:hover {
    background-color: var(--dark-blue);
}

.transparent, .h-transparent:hover {
    color: transparent !important;
}

.bg-transparent, .h-bg-transparent:hover {
    background-color: transparent !important;
}

.blur4 {
    backdrop-filter: blur(var(--space4));
}

.blur8 {
    backdrop-filter: blur(var(--space8));
}

.blur20 {
    backdrop-filter: blur(var(--space20));
}

.gradient-red, .h-gradient-red:hover {
    background-image: var(--gradient-red);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-gradient-red, .h-bg-gradient-red:hover {
    background: var(--gradient-red);
}

.gradient-orange, .h-gradient-orange:hover {
    background-image: var(--gradient-orange);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-gradient-orange, .h-bg-gradient-orange:hover {
    background: var(--gradient-orange);
}

.gradient-yellow, .h-gradient-yellow:hover {
    background-image: var(--gradient-yellow);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-gradient-yellow, .h-bg-gradient-yellow:hover {
    background: var(--gradient-yellow);
}

.gradient-green, .h-gradient-green:hover {
    background-image: var(--gradient-green);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-gradient-green, .h-bg-gradient-green:hover {
    background: var(--gradient-green);
}

.gradient-blue, .h-gradient-blue:hover {
    background-image: var(--gradient-blue);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-gradient-blue, .h-bg-gradient-blue:hover {
    background: var(--gradient-blue);
}

.gradient-purple, .h-gradient-purple:hover {
    background-image: var(--gradient-purple);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-gradient-purple, .h-bg-gradient-purple:hover {
    background: var(--gradient-purple);
}

.gradient-pink, .h-gradient-pink:hover {
    background-image: var(--gradient-pink);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-gradient-pink, .h-bg-gradient-pink:hover {
    background: var(--gradient-pink);
}

.sunset-gradient, .h-sunset-gradient:hover {
    background-image: var(--sunset-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-sunset-gradient, .h-bg-sunset-gradient:hover {
    background: var(--sunset-gradient);
}

.off-white, .h-off-white:hover {
    color: var(--off-white);
}

.bg-off-white, .h-bg-off-white:hover {
    background-color: var(--off-white);
}
